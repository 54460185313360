import pain from "./serviceimages/pain.png";
import gym_injury from "./serviceimages/gym_injury.png";
import flexibility from "./serviceimages/flexibility.png";
import elder_care from "./serviceimages/elder_care.png";
import cancer_rehab from "./serviceimages/cancer_rehab.png";
import corporate_wellnes from "./serviceimages/corporate_wellness.png";

export const servicesData = [
  {
    id: 2,
    name: "Corporate Wellness",
    bulletPoints: [
      {
        id: 1,
        value:
          "Struggling with back pain, stiff shoulders, or fatigue from spending hours at your desk.",
      },
      {
        id: 2,
        value:
          "Feeling less productive at work due to constant aches and reduced concentration.",
      },
      {
        id: 3,
        value:
          "In need of personalized advice on posture, work ergonomics, and stretches to stay energized.",
      },
      {
        id: 4,
        value:
          "Worrying about the long-term impact of a sedentary lifestyle on your physical and mental health.",
      },
      {
        id: 5,
        value:
          "Wanting to maintain a healthy work-life balance without compromising your health.",
      },
    ],
    article: "<p>Full article content here</p>",
    imageLink: corporate_wellnes,
  },
  {
    id: 1,
    name: "Pain Management",
    bulletPoints: [
      {
        id: 1,
        value:
          "Constant discomfort and difficulty sleeping due to chronic pain is affecting your day-to-day life.",
      },
      {
        id: 2,
        value:
          "Struggling to find effective, non-invasive solutions for back, neck, or joint pain that keeps you from enjoying what you love.",
      },
      {
        id: 3,
        value:
          "Tired of depending on painkillers and want a natural way to relieve pain and improve well-being.",
      },
      {
        id: 4,
        value:
          "Experiencing flare-ups that make even simple activities feel exhausting and overwhelming.",
      },
      {
        id: 5,
        value:
          "Longing to regain the quality of life that pain has taken away from you.",
      },
    ],
    article: "<p>Full article content here</p>",
    imageLink: pain,
  },
  {
    id: 3,
    name: "Elder Care Program",
    bulletPoints: [
      {
        id: 1,
        value:
          "Facing challenges with reduced mobility, balance, or joint pain that makes daily activities difficult.",
      },
      {
        id: 2,
        value:
          "Feeling isolated and longing for personalized care that considers your unique needs.",
      },
      {
        id: 3,
        value:
          "Struggling to maintain independence in the face of aging, but not sure where to turn for help.",
      },
      {
        id: 4,
        value:
          "Experiencing falls or balance issues that make you feel unsafe moving around the house.",
      },
      {
        id: 5,
        value:
          "Wanting to stay active and healthy but needing support to prevent further health decline.",
      },
    ],
    article: "<p>Full article content here</p>",
    imageLink: elder_care,
  },
  {
    id: 4,
    name: "Cancer Rehab",
    bulletPoints: [
      {
        id: 1,
        value:
          "Feeling fatigued and weak due to the side effects of cancer treatment and needing a gentle recovery plan.",
      },
      {
        id: 2,
        value:
          "Experiencing decreased mobility, pain, or swelling that affects your ability to move comfortably.",
      },
      {
        id: 3,
        value:
          "In search of a program to regain physical strength and energy after chemotherapy or radiation therapy.",
      },
      {
        id: 4,
        value:
          "Wishing for support to improve your quality of life and help reduce anxiety during your recovery.",
      },
      {
        id: 5,
        value:
          "Wanting a tailored exercise plan that works with your body and doesn’t push you too hard.",
      },
    ],
    article: "<p>Full article content here</p>",
    imageLink: cancer_rehab,
  },
  {
    id: 5,
    name: "Flexibility Enhancement",
    bulletPoints: [
      {
        id: 1,
        value:
          "Experiencing stiff muscles and joints that limit your range of motion and keep you from moving freely.",
      },
      {
        id: 2,
        value:
          "Struggling to maintain good posture and feeling restricted in your everyday movements.",
      },
      {
        id: 3,
        value:
          "Wanting to prevent injuries that often occur because of poor flexibility and tight muscles.",
      },
      {
        id: 4,
        value:
          "Noticing that lack of flexibility is holding you back from improving your fitness or enjoying sports.",
      },
      {
        id: 5,
        value:
          "Wishing for guided stretching and mobility exercises to regain your youthful flexibility and ease of movement.",
      },
    ],
    article: "<p>Full article content here</p>",
    imageLink: flexibility,
  },
  {
    id: 6,
    name: "Prevent Gym Injury",
    bulletPoints: [
      {
        id: 1,
        value:
          "Feeling unsure about proper workout techniques and afraid of getting injured while exercising.",
      },
      {
        id: 2,
        value:
          "Experiencing minor aches that make you worry about the risk of a more serious injury.",
      },
      {
        id: 3,
        value:
          "Noticing uneven muscle development or pain, which makes you question whether your routine is safe.",
      },
      {
        id: 4,
        value:
          "Wanting expert guidance on how to prevent common gym injuries so you can train without worry.",
      },
      {
        id: 5,
        value:
          "Struggling to find a balance between pushing your limits and avoiding overtraining or strain.",
      },
    ],
    article: "<p>Full article content here</p>",
    imageLink: gym_injury,
  },
];
