import React, { useEffect } from "react";

const ZohoChatWidget = () => {
  useEffect(() => {
    const scriptId = "zsiqscript";
    let script;

    // Check if script already exists in the DOM
    if (!document.getElementById(scriptId)) {
      // Create and add the script if it does not exist
      script = document.createElement("script");
      script.id = scriptId;
      script.src =
        "https://salesiq.zohopublic.in/widget?wc=siq0590a2e434bdeff892549370e9703d4d5d6eaa2880575d65c96e74d7e3301f9d";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }

    // Cleanup: Safely attempt to remove the script if it exists in the DOM
    return () => {
      const scriptInDOM = document.getElementById(scriptId);
      if (scriptInDOM && scriptInDOM.parentNode === document.body) {
        document.body.removeChild(scriptInDOM);
        console.log("Zoho Chat Widget script removed on component unmount.");
      }
    };
  }, []);

  return null; // No UI for this component
};

export default ZohoChatWidget;
